<template>
  <Content>
    <template #contentTitle>{{ $t('menu.user_address') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between">
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
              style="width: 250px"
                v-model:value="searchState.contactName"
                :placeholder="$t('user.address_name')"
                allow-clear
              ></a-input>
            </a-col>
            <a-col>
              <a-select
              style="width: 250px"
                v-model:value="searchState.countryId"
                :placeholder="$t('common.country')"
                optionFilterProp="search-key"
                show-search
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in countryList"
                  :key="index"
                  :value="item.id"
                  :title="getLanguageName(item.cnName, item.enName)+'('+item.ioS2+')'"
                  :search-key="item.cnName + item.enName + item.ioS2 "
                >{{ getLanguageName(item.cnName, item.enName) }}({{item.ioS2}})</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-input
              style="width: 250px"
                v-model:value="searchState.postCode"
                :placeholder="$t('common.postcode')"
                allow-clear
              ></a-input>
            </a-col>
            <a-col>
              <a-input
              style="width: 250px"
                v-model:value="searchState.address"
                :placeholder="$t('warehouse.detail_address')"
                allow-clear
              ></a-input>
            </a-col>
            <a-col>
              <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.query') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary" ghost @click="handleOpenModal('')">{{ $t('common.create') }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #phone="{ record }">
          {{ record.telephoneCountryTelCode }}-{{ record.telephone }}
        </template>
        <template #country="{ record }">{{ getLanguageName(record.countryCnName, record.countryEnName) }}</template>
        <template #province="{ record }">{{ getLanguageName(record.provinceCnName, record.provinceEnName) }}</template>
        <template #city="{ record }">{{ getLanguageName(record.cityCnName, record.cityEnName) }}</template>
        <template #county="{ record }">{{ getLanguageName(record.countyCnName, record.countyEnName) }}</template>
        <template #address2="{ record }">
          <span v-if="record.address2">{{ record.address2 }}</span>
          <span v-else>-</span>
        </template>
        <template #operate="{ record }">
          <a-dropdown-button @click="handleOpenModal(record.id)">
            {{ $t("common.edit") }}
           <template #overlay>
             <a-menu @click="handleClickOperateMenu">
               <a-menu-item :key="1" :record="record">
                 {{ $t("common.delete") }}
               </a-menu-item>
             </a-menu>
           </template>
         </a-dropdown-button>
        </template>
      </a-table>
      <AddressModal ref="addressModalRef" @refresh="handleSearch" />
    </template>
    <template #contentFooter>
      <c-pager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      />
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, onMounted, createVNode } from 'vue'
import Content from '@/views/components/Content.vue'
import CPager from '@/views/components/CPager.vue'
import AddressModal from './components/AddressModal.vue'
import { Button, Col, Dropdown, Input, Menu, message, Modal, Row, Select, Table } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { getCountrys } from '@/api/modules/common/index'
import { getCommonContacterPagedList, deleteCommonContacter } from '@/api/modules/user/address/index'
import { getName } from "@/utils/general"

export default defineComponent({
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    AddressModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const addressModalRef = ref(null);

    const state = reactive({
      countryList: [],
      searchState: {
        contactName: null,
        countryId: null,
        address: null,
        postCode: null,
      },
      searchStateCache: {},
      tableData: {
        loading: true,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        }
      }
    })

    const columns = [
      {
        dataIndex: "contactName",
        title: () => vueI18n.t('user.address_name'),
        width: "150",
      },
      {
        title: () => vueI18n.t('account.phone_number'),
        width: "100",
        slots: {
          customRender: "phone"
        }
      },
      {
        title: () => vueI18n.t('common.country'),
        width: "100",
        slots: {
          customRender: "country"
        }
      },
      {
        title: () => vueI18n.t('common.province'),
        width: "100",
        slots: {
          customRender: "province"
        }
      },
      {
        title: () => vueI18n.t('common.city'),
        width: "100",
        slots: {
          customRender: "city"
        }
      },
      {
        title: () => vueI18n.t('common.county'),
        width: "100",
        slots: {
          customRender: "county"
        }
      },
      {
        dataIndex: "postCode",
        title: () => vueI18n.t('common.postcode'),
        width: "100",
      },
      {
        dataIndex: "address",
        title: () => vueI18n.t('warehouse.detail_address'),
        width: "150",
      },
      {
        title: () => vueI18n.t('warehouse.detail_address') + '2',
        width: "150",
        slots: {
          customRender: "address2"
        }
      },
      {
        title: () => vueI18n.t('common.operation'),
        width: "120",
        slots: {
          customRender: "operate"
        }
      },
    ]

    const getLanguageName = (cnName = '', enName = '') => {
      if (cnName && enName) {
        return getName({ cnName, enName }, getters.language);
      }
      return '-'
    }

    const handleOpenModal = (id) => {
      addressModalRef.value.open(id)
    }

    const handleClickOperateMenu = (e) => {
      let { key, item } = e
      let { record } = item

      const func = {
        1: handleDeleteAddress,
      }

      func[key] && func[key](record)
    }

    const handleDeleteAddress = (record) => {
      Modal.confirm({
        title: vueI18n.t('common.operation'),
        icon: createVNode(ExclamationCircleOutlined),
        content: vueI18n.t('common.Are_you_sure_to_delete_this_piece_of_data'),
        okText: vueI18n.t('common.confirm'),
        cancelText: vueI18n.t('common.cancel'),
        onOk: () => {
          return deleteCommonContacter({ id: record.id }).then(() => {
            message.success(vueI18n.t('common.succeed'))
            handleSearch()
          }).catch(() => {})
        }
      })
    }

    const getPageList = () => {
      state.tableData.loading = true
      const data = Object.assign({}, state.tableData.pageData, state.searchStateCache)
      getCommonContacterPagedList(data).then(({ result }) => {
        let { items = [], totalCount = 0 } = result;
        state.tableData.pageData.totalCount = parseInt(totalCount);
        state.tableData.tableList = items;
      }).catch(() => {}).finally(() => {
        state.tableData.loading = false
      })
    }

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState)
      state.tableData.pageData.currentIndex = 1;
      state.tableData.pageData.skipCount = 0;
      getPageList();
    };

    const getCountryList = () => {
      getCountrys().then(({ result }) => {
        if (result) {
          state.countryList = result
        }
      }).catch(() => {})
    }

    onMounted(() => {
      getCountryList()
      getPageList()
    })

    return {
      ...toRefs(state),
      addressModalRef,
      columns,
      getLanguageName,
      handleOpenModal,
      handleClickOperateMenu,
      handlePage,
      handleSearch,
    }
  }
})
</script>

<style scoped>

</style>