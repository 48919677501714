import client from "../../../client";

const Api = {
  // 地址列表
  getCommonContacterPagedList: "/api/ProductService/CommonContacter/GetPagedList",
  // 获取地址详情
  getCommonContacterById: "/api/ProductService/CommonContacter/GetById",
  // 新增地址
  createCommonContacter: "/api/ProductService/CommonContacter/Create",
  // 修改地址
  updateCommonContacter: "/api/ProductService/CommonContacter/Update",
  // 删除地址
  deleteCommonContacter: "/api/ProductService/CommonContacter/Delete",
};

export const getCommonContacterPagedList = (data) => {
  return client.request({
    url: Api.getCommonContacterPagedList,
    data,
    method: "POST",
  });
};

export const getCommonContacterById = (data) => {
  return client.request({
    url: Api.getCommonContacterById,
    params: data,
    method: "GET",
  });
};

export const createCommonContacter = (data) => {
  return client.request({
    url: Api.createCommonContacter,
    data,
    method: "POST",
  });
};

export const updateCommonContacter = (data) => {
  return client.request({
    url: Api.updateCommonContacter,
    data,
    method: "POST",
  });
};

export const deleteCommonContacter = (data) => {
  return client.request({
    url: Api.deleteCommonContacter,
    data,
    method: "POST",
  });
};
