<template>
  <a-modal
    v-model:visible="visible"
    :title="!!formState.id ? $t('common.edit') : $t('common.add')"
    :confirm-loading="confirmLoading"
    :mask-closable="false"
    @ok="handleConfirm"
  >
    <a-spin :spinning="loading">
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-item :label="$t('user.address_name')" name="contactName">
          <a-input
            v-model:value="formState.contactName"
            v-user-name-filter
            :placeholder="$t('user.address_name')"
          ></a-input>
        </a-form-item>
        <a-form-item :label="$t('account.phone_number')" name="contactPhone">
          <a-input-group class="max-w-330" style="display: flex;" compact>
            <a-select
              style="width: 100px;"
              v-model:value="formState.telephoneCountryId"
              :placeholder="$t('logistics.area_code')"
              :loading="phoneCountryLoading"
              option-label-prop="label"
              optionFilterProp="search-key"
              show-search
              @change="handleValidatePhone"
            >
              <a-select-option
                v-for="(item, index) in phoneCountryList"
                :key="index"
                :value="item.id"
                :label="`+${item.telCode}`"
                :search-key="item.ioS2 + item.cnName + item.enName + '+' + item.telCode"
                :title="`+${item.telCode}(${getLanguageName(item)})`"
              >+{{ item.telCode }}({{ getLanguageName(item) }})</a-select-option>
            </a-select>
            <a-input
              style="flex: 1;"
              v-model:value="formState.telephone"
              :placeholder="$t('warehouse.phone')"
              @change="handleValidatePhone"
            />
          </a-input-group>
        </a-form-item>
        <a-form-item :label="$t('common.country')" name="countryId">
          <a-select
            v-model:value="formState.countryId"
            :loading="countryLoading"
            :showSearch="true"
            optionFilterProp="search-key"
            :placeholder="$t('common.country')"
            @change="handleCountryChange"
          >
            <a-select-option
              v-for="item in countryList"
              :search-key="item.cnName + item.enName+item.ioS2"
              :key="item.id"
              :title="getLanguageName(item)+'('+item.ioS2+')'"
            >{{ getLanguageName(item) }}({{item.ioS2}})</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('common.province')" name="provinceId">
          <a-select
            v-model:value="formState.provinceId"
            :loading="provinceLoading"
            :showSearch="true"
            optionFilterProp="search-key"
            :placeholder="$t('common.province')"
            @change="handleProvinceChange"
          >
            <a-select-option
              v-for="item in provinceList"
              :search-key="item.cnName + item.enName+item.abbrCode"
              :key="item.id"
              :title="getLanguageName(item)+item.abbrCode"
            >  {{getLanguageName(item) + (item.abbrCode?"("+item.abbrCode+")":"")}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('common.city')" name="cityId">
          <a-select
            v-model:value="formState.cityId"
            :loading="cityLoading"
            :showSearch="true"
            optionFilterProp="search-key"
            :placeholder="$t('common.city')"
            @change="handleCityChange"
          >
            <a-select-option
              v-for="item in cityList"
              :search-key="item.cnName + item.enName"
              :key="item.id"
              :title="getLanguageName(item)"
            >{{ getLanguageName(item) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('common.county')" v-if="countyList.length">
          <a-select
            v-model:value="formState.countyId"
            :loading="countyLoading"
            :showSearch="true"
            optionFilterProp="search-key"
            :placeholder="$t('common.county')"
          >
            <a-select-option
              v-for="item in countyList"
              :search-key="item.cnName + item.enName"
              :key="item.id"
              :title="getLanguageName(item)"
            >{{ getLanguageName(item) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('common.postcode')" name="postCode">
          <a-input
            v-model:value="formState.postCode"
            :placeholder="$t('common.postcode')"
          ></a-input>
        </a-form-item>
        <a-form-item :label="$t('warehouse.detail_address')" name="address">
          <a-input
            v-model:value="formState.address"
            v-address-filter
            :placeholder="$t('warehouse.detail_address')"
          ></a-input>
        </a-form-item>
        <a-form-item :label="$t('warehouse.detail_address') + '2'">
          <a-input
            v-model:value="formState.address2"
            v-address-filter
            :placeholder="$t('warehouse.detail_address') + '2'"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue'
import { Form, Input, message, Modal, Select, Spin } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { getName, verifyMobilePhoneNumber } from "@/utils/general"
import { getGlobalCountrys, getCountrys, getProvinces, getCitys, getCountys } from '@/api/modules/common/index'
import { getCommonContacterById, createCommonContacter, updateCommonContacter } from '@/api/modules/user/address/index'

export default defineComponent({
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInput: Input,
    AInputGroup: Input.Group,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const formRef = ref(null)

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      formState: {
        id: null,
        contactName: null,
        telephoneCountryId: null,
        telephone: null,
        contactName: null,
        countryId: null,
        provinceId: null,
        cityId: null,
        countyId: null,
        postCode: null,
        address: null,
        address2: null,
        containerTelephone: true
      },
      phoneCountryLoading: false,
      phoneCountryList: [],
      countryLoading: false,
      countryList: [],
      provinceLoading: false,
      provinceList: [],
      cityLoading: false,
      cityList: [],
      countyLoading: false,
      countyList: [],
    })

    const addressCache = {
      provinces: {},
      cities: {},
      counties: {},
    }

    const validatePhone = (rule, value) => {
      if (!state.formState.telephoneCountryId) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.area_code')]));
      }
      if (!state.formState.telephone) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('account.phone_number')]));
      }
      let country = state.phoneCountryList.find(item => item.id === state.formState.telephoneCountryId)
      let countryCode = country?.ioS2
      let phoneCode = country?.telCode ?? ''
      let phone = phoneCode + state.formState.telephone;
      if (!verifyMobilePhoneNumber(countryCode, phone)) {
        return Promise.reject(vueI18n.t('common.p0_format_error', [vueI18n.t('account.phone_number')]));
      }
      return Promise.resolve();
    }

    const rules = {
      contactName: [
        {
          required: true,
          message: vueI18n.t('common.p0_is_required', [vueI18n.t('user.address_name')]),
        },
        {
          trigger: ['change', 'blur'],
          pattern: /^.{1,32}$/,
          message: vueI18n.t("common.p0_up_to_p1_characters", [vueI18n.t("user.address_name"), 32])
        }
      ],
      countryId: {
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('common.country')]),
      },
      provinceId: {
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('common.province')]),
      },
      cityId: {
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('common.city')]),
      },
      postCode: {
        required: true,
        validator: (rule, value) => {
          if (!value||value.trim().length == 0) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("common.postcode")]));
          }

          if (value && value?.trim().length < 5) {
            return Promise.reject(vueI18n.t("common.p0_please_enter_at_least_p1_digits", [vueI18n.t("common.postcode"),5]));
          }
          return Promise.resolve();
        },
      },
      address: {
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.detail_address')]),
      },
    }

    const handleValidatePhone = () => {
      formRef.value.validate(['contactPhone'])
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const getCountryList = () => {
      state.countryLoading = false
      getCountrys().then(({ result }) => {
        if (result) {
          state.countryList = result
        }
      }).catch(() => {}).finally(() => {
        state.countryLoading = false
      })
    }

    const getProvinceList = async () => {
      try {
        state.provinceLoading = false
        let countryId = state.formState.countryId + "";
        if (addressCache.provinces[countryId]) {
          state.provinceList = addressCache.provinces[countryId]
        } else {
          let { result } = await getProvinces({ countryId })
          addressCache.provinces[countryId] = result
          state.provinceList = result
        }
      } catch (error) {
        
      } finally {
        state.provinceLoading = false
      }
    }

    const getCityList = async () => {
      try {
        state.cityLoading = false
        let provinceId = state.formState.provinceId + "";
        if (addressCache.cities[provinceId]) {
          state.cityList = addressCache.cities[provinceId]
        } else {
          let { result } = await getCitys({ provinceId })
          addressCache.cities[provinceId] = result
          state.cityList = result
        }
      } catch (error) {
        
      } finally {
        state.cityLoading = false
      }
    }

    const getCountyList = async () => {
      try {
        state.countyLoading = true
        let cityId = state.formState.cityId + "";
        if (addressCache.counties[cityId]) {
          state.countyList = addressCache.counties[cityId]
        } else {
          let { result } = await getCountys({ cityId })
          addressCache.counties[cityId] = result
          state.countyList = result
        }
      } catch (error) {
        
      } finally {
        state.countyLoading = false
      }
    }

    const handleCountryChange = () => {
      state.formState.provinceId = null
      state.formState.cityId = null
      state.formState.countyId = null

      state.provinceList = []
      state.cityList = []
      state.countyList = []

      getProvinceList()
    }

    const handleProvinceChange = () => {
      state.formState.cityId = null
      state.formState.countyId = null

      state.cityList = []
      state.countyList = []

      getCityList()
    }

    const handleCityChange = () => {
      state.formState.countyId = null
      state.countyList = []

      getCountyList()
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate()
        state.confirmLoading = true
        const data = Object.assign({}, state.formState)
        if (data.id) {
          await updateCommonContacter(data)
        } else {
          await createCommonContacter(data)
        }
        message.success(vueI18n.t('common.succeed'))
        state.visible = false
        emit('refresh')
      } catch (error) {
        
      } finally {
        state.confirmLoading = false
      }
    }

    const handleGetDetail = (id) => {
      state.loading = true
      getCommonContacterById({ id }).then(({ result }) => {
        for (const key in result) {
          if (Object.hasOwnProperty.call(state.formState, key)) {
            state.formState[key] = result[key]
          }
        }
        if (state.formState.countryId) {
          getProvinceList()
        }
        if (state.formState.provinceId) {
          getCityList()
        }
        if (state.formState.cityId) {
          getCountyList()
        }
      }).catch(() => {}).finally(() => {
        state.loading = false
      })
    }

    const getPhoneCountriesList = () => {
      state.phoneCountryLoading = true
      getGlobalCountrys().then(({result}) => {
        if (Array.isArray(result)) {
          state.phoneCountryList = result
        } else {
          state.phoneCountryList = []
        }
      }).catch(() => {}).finally(() => {
        state.phoneCountryLoading = false
      })
    }

    const open = (id) => {
      state.visible = true
      state.formState.countyId = null
      state.formState.address2 = null

      if (state.phoneCountryList.length === 0) {
        getPhoneCountriesList()
      }

      if (state.countryList.length === 0) {
        getCountryList()
      }

      nextTick(() => {
        formRef.value.resetFields()
        state.formState.telephoneCountryId = null
        state.formState.telephone = null
        if (id) {
          state.formState.id = id
          handleGetDetail(id)
        } else {
          state.formState.id = null
        }
      })
    }

    return {
      ...toRefs(state),
      formRef,
      rules,
      getLanguageName,
      handleValidatePhone,
      handleConfirm,
      getProvinceList,
      getCityList,
      getCountyList,
      handleCountryChange,
      handleProvinceChange,
      handleCityChange,
      open,
    }
  }
})
</script>

<style scoped>

</style>